import React from 'react';
import { CellText, Label, Grid } from '@increasecard/typed-components';
import styled from 'styled-components';
import { Container } from '../../components/Container';
import { TwitterIcon } from '../../icons/TwitterIcon';
import { FacebookIcon } from '../../icons/FacebookIcon';
import { LinkedinIcon } from '../../icons/LinkedinIcon';
import { YouTubeIcon } from '../../icons/YouTubeIcon';
import { MediumIcon } from '../../icons/MediumIcon';
import { InstagramIcon } from '../../icons/InstagramIcon';
import { SmartLink } from '../../components/SmartLink';
import { DataFiscalIcon } from '../../icons/DataFiscalIcon';
import { MEDIUM } from '../../breakpoints';

const FooterWrapper = styled.nav`
  width: 100%;
  padding: 46px 32px;
  margin: 0 auto;
  background: ${({ theme }) => theme.colorsNew.gray.black};
  color: ${({ theme }) => theme.colorsNew.gray.white};

  @media (min-width: ${MEDIUM}) {
    padding: 32px 0;
  }
`;

const FooterContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${MEDIUM}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FooterCopyrightNoticeContainer = styled(Container)`
  margin-top: 32px;
  @media (min-width: ${MEDIUM}) {
    margin-top: 48px;
  }
`;

const FooterLinkList = styled.ul`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 32px;
  grid-template-rows: repeat(auto-fit, 16px);
  grid-template-columns: 100%;
  margin-bottom: 64px;

  @media (min-width: ${MEDIUM}) {
    margin-bottom: 0;
    grid-gap: 16px;
    grid-auto-flow: column;
    margin-right: 64px;
    grid-template: repeat(7, min-content) / repeat(auto-fit, 144px);
  }
`;

const FooterLinkGroups = styled.ul`
  display: flex;
  width: auto;
  flex-direction: column;

  @media (min-width: ${MEDIUM}) {
    flex-direction: row;
  }
`;

const FooterLinkGroup = styled.li`
  display: flex;
  flex-direction: column;
`;

const FooterLinkSection = styled(Label).attrs({ as: 'a' })`
  color: ${({ theme }) => theme.colorsNew.increase.accent};
  text-transform: uppercase;
  font-size: 14px;

  :hover {
    text-decoration: underline;
  }
`;

const FooterLinkLink = styled(CellText).attrs({ as: SmartLink })`
  :hover {
    text-decoration: underline;
  }
`;

const SocialLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;

  @media (min-width: ${MEDIUM}) {
    margin-top: 0;
  }
`;

const SocialLinks = styled.ul`
  display: grid;
  grid-template-columns: repeat(6, max-content);
  grid-column-gap: 16px;
`;

const SocialLinksTitle = styled(Label)`
  margin-bottom: 16px;
`;

const SocialLink = styled(SmartLink)`
  color: ${({ theme }) => theme.colorsNew.increase.accent};

  :hover {
    color: ${({ theme }) => theme.colorsNew.gray.white};
  }
`;

const Certifications = styled.ul`
  display: grid;
  align-content: center;
  grid-template-columns: repeat(4, max-content);
  grid-column-gap: 16px;
`;

export function Footer({
  links,
  reducedNavigationLinks,
  socialLinks,
  fiscalDataUrl,
  layout,
}) {
  const effectiveLinks =
    layout === 'fullNavigation' ? links : reducedNavigationLinks;
  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterLinkGroups>
          {effectiveLinks.map(({ name, url, sublinks, visible }) => {
            if (!visible) return null;
            return (
              <FooterLinkGroup
                key={url}
                columns={Math.ceil(sublinks.length / 5)}
              >
                <ul>
                  <li style={{ marginBottom: '24px' }}>
                    <FooterLinkSection href={url}>{name}</FooterLinkSection>
                  </li>
                  <li>
                    <FooterLinkList>
                      {sublinks.map(
                        ({ name, url, visible }) =>
                          visible !== false && (
                            <li key={url}>
                              <FooterLinkLink href={url}>{name}</FooterLinkLink>
                            </li>
                          )
                      )}
                    </FooterLinkList>
                  </li>
                </ul>
              </FooterLinkGroup>
            );
          })}
        </FooterLinkGroups>
        <Grid rowGap={4}>
          <SocialLinksContainer>
            <SocialLinksTitle>Seguinos en</SocialLinksTitle>
            <SocialLinks>
              <li>
                <SocialLink aria-label="Twitter" href={socialLinks.twitter}>
                  <TwitterIcon />
                </SocialLink>
              </li>
              <li>
                <SocialLink aria-label="Facebook" href={socialLinks.facebook}>
                  <FacebookIcon />
                </SocialLink>
              </li>
              <li>
                <SocialLink aria-label="Instagram" href={socialLinks.instagram}>
                  <InstagramIcon />
                </SocialLink>
              </li>
              <li>
                <SocialLink aria-label="Linkedin" href={socialLinks.linkedin}>
                  <LinkedinIcon />
                </SocialLink>
              </li>
              <li>
                <SocialLink aria-label="YouTube" href={socialLinks.youTube}>
                  <YouTubeIcon />
                </SocialLink>
              </li>
              <li>
                <SocialLink aria-label="Medium" href={socialLinks.medium}>
                  <MediumIcon />
                </SocialLink>
              </li>
            </SocialLinks>
          </SocialLinksContainer>
          <Certifications>
            <li>
              <a href={fiscalDataUrl}>
                <DataFiscalIcon />
              </a>
            </li>
          </Certifications>
        </Grid>
      </FooterContainer>
      <FooterCopyrightNoticeContainer>
        <CellText>© Increase</CellText>
      </FooterCopyrightNoticeContainer>
    </FooterWrapper>
  );
}
